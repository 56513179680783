import React, { createContext, ReactNode, useState } from 'react';

export interface ChatBubbleProps {
  className?: string;
  title?: ReactNode;
  message?: ReactNode;
  children?: ReactNode;
}

export interface AuthState {
  user?: {
    id: number;
    name: string;
    email: string;
    email_verified_at: string;
    role: string;
    created_at: string;
    updated_at: string;
  };
}

interface ISuperContext {
  bubble: ChatBubbleProps;
  setBubble: (bubble: ChatBubbleProps) => void;
  showingNavigationDropdown: boolean;
  setShowingNavigationDropdown: (showingNavigationDropdown: boolean) => void;
  auth_: AuthState | null;
  setAuth_: (auth_: AuthState) => void;
}

const defaultState: ISuperContext = {
  bubble: { title: '', message: '', className: '' },
  setBubble: () => {},
  showingNavigationDropdown: false,
  setShowingNavigationDropdown: () => {},
  auth_: null,
  setAuth_: () => {},
};

export const SuperContext = createContext<ISuperContext>(defaultState);

interface SuperProviderProps {
  children: ReactNode;
}

export const SuperProvider: React.FC<SuperProviderProps> = ({ children }) => {
  const [bubble, setBubble] = useState<ChatBubbleProps>({
    title: '',
    message: '',
    className: '',
  });

  const [showingNavigationDropdown, setShowingNavigationDropdown] =
    useState(false);

  const [auth_, setAuth_] = useState({});

  return (
    <SuperContext.Provider
      value={{
        bubble,
        setBubble,
        showingNavigationDropdown,
        setShowingNavigationDropdown,
        auth_,
        setAuth_,
      }}
    >
      {children}
    </SuperContext.Provider>
  );
};
